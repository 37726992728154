*{
--orange: '#fed693'
}
.heroneon_white {
    position: relative;
    display: inline-block;
    padding: 20px 25px;
    margin: 20px 0;
    color: #fff;
    text-decoration: none;
    text-transform: uppercase;
    transition: 0.5s;
    letter-spacing: 4px;
    overflow: hidden;
    font-size: 1.2rem;
    font-weight: 700;
  }

  @media screen and (max-width: 768px) {
    .heroneon_white {
      font-size: 1rem;
    }
  }
  .heroneon_white:hover {
    background: linear-gradient(-45deg, #ffb401, #ff0000);
    color: #fff;
    box-shadow: 0 0 5px #f39a05, 0 0 25px #f39a05, 0 0 50px #f39a05,
      0 0 200px #f39a05;
    -webkit-box-reflect: below 1px linear-gradient(transparent, #0005);
  }
  .heroneon_white:nth-child(1) {
    filter: hue-rotate(0deg);
  }
  .heroneon_white:nth-child(2) {
    filter: hue-rotate(110deg);
  }
  .heroneon_white span {
    position: absolute;
    display: block;
  }
  .heroneon_white span:nth-child(1) {
    top: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background: linear-gradient(90deg, transparent, #fff);
    animation: animate1 1s linear infinite;
  }
  @keyframes animate1 {
    0% {
      left: -100%;
    }
    50%,
    100% {
      left: 100%;
    }
  }
  .heroneon_white span:nth-child(2) {
    top: -100%;
    right: 0;
    width: 2px;
    height: 100%;
    background: linear-gradient(180deg, transparent, #fff);
    animation: animate2 1s linear infinite;
    animation-delay: 0.25s;
  }
  @keyframes animate2 {
    0% {
      top: -100%;
    }
    50%,
    100% {
      top: 100%;
    }
  }
  .heroneon_white span:nth-child(3) {
    bottom: 0;
    right: 0;
    width: 100%;
    height: 2px;
    background: linear-gradient(270deg, transparent, #fff);
    animation: animate3 1s linear infinite;
    animation-delay: 0.5s;
  }
  @keyframes animate3 {
    0% {
      right: -100%;
    }
    50%,
    100% {
      right: 100%;
    }
  }
  .heroneon_white span:nth-child(4) {
    bottom: -100%;
    left: 0;
    width: 2px;
    height: 100%;
    background: linear-gradient(360deg, transparent, #fff);
    animation: animate4 1s linear infinite;
    animation-delay: 0.75s;
  }
  @keyframes animate4 {
    0% {
      bottom: -100%;
    }
    50%,
    100% {
      bottom: 100%;
    }
  }