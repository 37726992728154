.testimonial {
    width: 400px;
    max-width: 100%;
    background: #f9f9f8;
    padding: 4em 3em;
    margin: 2rem;
    display: flex;
    align-items: flex-end;
    position: relative;
    box-shadow: 0 2px 2px #fff, 0 3px 3px #000, 0 5px 5px #000, 0 9px 9px #000, 0 17px 17px #000;
    position: relative;
}
.testimonial:after {
    content: "";
    border: 8px solid #f9f9f8;
    border-radius: 50px;
    width: 85%;
    height: 120%;
    position: absolute;
    z-index: -1;
    left: 1.5em;
    top: -2em;
}
/* .testimonial:before {
    content: "";
    position: absolute;
    bottom: -5.2em;
    left: 5em;
    z-index: 1;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 70px 100px 0 0;
    border-color: #f9f9f8 transparent transparent transparent;
} */
.testimonial .quote {
    position: absolute;
    font-size: 3em;
    width: 40px;
    height: 40px;
    background: red;
    color: white;
    text-align: center;
    line-height: 1.25;
}
.testimonial .quote.open {
    top: 0;
    left: 0;
}
.testimonial .quote.close {
    bottom: 0;
    right: 0;
}
.testimonial p {
    width: 80%;
    display: inline-block;
    font-weight: bold;
    font-size: 1em;
    color: #000;
}
.testimonial .source {
    width: 40%;
    height: 100%;
    position: relative;
}
.testimonial .source span {
    display: inline-block;
    font-weight: bold;
    font-size: 1em;
    position: relative;
    margin-left: 1rem;
    text-align: right;
}
.testimonial .source span:before {
    content: "\2014";
    display: inline;
    margin-right: 5px;
}
.testimonial .image {
    transform: rotate(-5deg);
    position: absolute;
    top: 0.5em;
    right: 1.5em;
}
.testimonial .image img {
    border: 5px solid red;
    margin: 0;
    padding: 0;
    max-width:100px;
}
.testimonial .image .clip {
    border: 2px solid #fff;
    border-right: none;
    height: 75px;
    width: 20px;
    position: absolute;
    right: 30%;
    top: -15%;
    border-radius: 25px;
}
.testimonial .image .clip:before {
    content: "";
    position: absolute;
    top: -1px;
    right: 0;
    height: 10px;
    width: 16px;
    border: 2px solid #222;
    border-bottom: none;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    z-index: 99;
}
.testimonial .image .clip:after {
    content: "";
    position: absolute;
    bottom: -1px;
    right: 0;
    height: 40px;
    width: 16px;
    border: 2px solid #222;
    border-top: none;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
    z-index: 99;
}