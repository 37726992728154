.quotes {
    position: relative;
    width: 350px;
    margin: 1rem;
    padding: 1rem;
    background-color: transparent;
    background-image: linear-gradient(-45deg, transparent, transparent 2%, white 2%, white 85%, transparent, 85%, transparent), linear-gradient(to bottom right, #2f2f2f, #2f2f2f 90%, transparent 90%);
    background-position: top left, 0.2em 0.2em;
    background-repeat: no-repeat;
    filter: drop-shadow(0 0 15px #000 5);
    height: 300px;
}
.quotes::before, .quotes::after {
    position: absolute;
    z-index: -1;
    display: block;
    width: 3rem;
    height: 4rem;
    content: "";
    z-index: 0;
    font-size: 8rem;
}
.quotes::before {
    top: -3.5rem;
    left: 1rem;
    content: open-quote;
    color: #ff0000;
}
.quotes::after {
    bottom: -0.5rem;
    right: 1rem;
    content: close-quote;
    color: #ff0000;
}
.quotes::before, .quotes::after, .quotes h2 {
    text-shadow: -2px 2px #fff, -1.5px 1.5px #fff, -1px 1px #fff, -0.5px 0.5px #fff;
}
.h2-quote {
    margin-top: 2rem;
}

.h6-quote {
    margin-top: 1.5rem;
    margin: 0 auto;
    text-align: center;
    max-width: 80%;
}



.p-quote {
    margin: 1rem;
    text-indent: 2ch;
    text-align: justify;
}


.wrapper {
    padding: 10px;
    /* background: #eaeaea; */
    max-width: 400px;
    margin-top: 20px ;
  }
  
  .demo-1 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  
  .demo-2 {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 400px;
  }