.tooltip-container1 {
    --background: #fff;
    position: relative;
    background: var(--background);
    cursor: pointer;
    transition: background 0.3s;
    font-size: 17px;
    padding: 0.7em 1.8em;
  }
  
  .tooltip1 {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%); /* Adjusted the initial position */
    opacity: 0;
    pointer-events: none;
    transition: all 0.3s cubic-bezier(0.68, -0.55, 0.27, 1.55);
    background: #fff;
    color: #ff0000;
    border-radius: 0.3em;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    text-align: center;
    font-size: 14px;
    width: 120%; /* Adjusted the width */
    padding: 0.5em 1em; /* Adjusted padding */
    white-space: nowrap; /* Prevent text wrapping */
  }
  
  .tooltip-container1:hover .tooltip1 {
    top: -50%; /* Adjusted the tooltip position */
    opacity: 1;
    pointer-events: auto;
    transform: translateX(-50%) translateY(0);
  }



  
  @media (max-width: 640px), (pointer: coarse), (hover: none)  {
    .tooltip-container1:focus::after .tooltip1 {
        top: -50%; /* Adjusted the tooltip position */
        opacity: 1;
        pointer-events: auto;
        transform: translateX(-50%) translateY(0);
      }
  }
  