.text-container {
  /* height: 100vh;
    width: 100vw; */
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-top: 15rem; */

}

@media screen and (max-width: 768px) {
  .text-container{
    margin-top: 5rem;
  }
}

.text-container h1 {
  color: rgba(225, 225, 225, 0.01);
  background-image: url("https://images.pexels.com/photos/3844796/pexels-photo-3844796.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1");
  background-repeat: repeat;
  -webkit-background-clip: text;
  animation: animate 5s ease-in-out infinite;
}

@keyframes animate {
  0%,
  100% {
    background-position: left top;
  }
  25% {
    background-position: right bottom;
  }
  50% {
    background-position: left bottom;
  }
  75% {
    background-position: right top;
  }
}
