.buttonPopup1 {
  position: fixed;
  bottom: 8rem;
  right: 7rem;
  width: 100px;
  border: 1px solid yellow;

  display: flex;
  z-index:1000;
  padding: 0.5rem;
  flex-direction: column;
  border-radius: 9999px;
  color: #ffffff;
  background-color: transparent;
  transition-duration: 700ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  cursor: pointer;
  align-items: center;
  border: none;
}

@media (max-width: 640px) {
  .buttonPopup1 {
    right: -0.5rem;
    bottom: 6rem;
  }
}





.buttonPopup {
  position: fixed;
  bottom: 4rem;
  right: 6rem;

  display: flex;
  z-index:1000;
  padding: 0.5rem;
  flex-direction: column;
  border-radius: 9999px;
  color: #ffffff;
  background-color: transparent;
  transition-duration: 700ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  cursor: pointer;
  align-items: center;
  border: none;
}

@media (max-width: 640px) {
  .buttonPopup {
    right: -1.2rem;
    bottom: 2rem;
  }
}

.buttonPopup:hover {
  /* background-color: #bfdbfe;
  scale: 1.1; */
}

.backtoTop {
  display: none;
  position: absolute;
  bottom: 1.5rem;
  left: 3.5rem;
  z-index: 10;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  flex-direction: column;
  border-radius: 9999px;
  color: #ffffff;
  cursor: pointer;
}

@media (min-width: 640px) {
  .backtoTop {
    display: block;
    left: 1rem;
    bottom: 5rem;
  }
}

.buttonSvg {
  width: 2rem;
  height: 2rem;
  /* border: 1px solid #fff; */
  margin-bottom: 5px;
}

.modalContainer {
  display: flex;
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  z-index: 1001;
  flex-direction: column;
  border-radius: 1rem;
  background-color: #ffffff;
}

.modalContainerInside {
  display: flex;
  padding-top: 1rem;
  padding-left: 1rem;
  flex-direction: column;
}

.modalh5 {
  padding-top: 1rem;
  font-size: 1.5rem;
  line-height: 2.5rem;
  font-weight: 500;
  letter-spacing: -0.025em;
  color: #ff0000;
  cursor: pointer;
  border-bottom: 1px solid #ff0000;
}

.modalp {
  padding-top: 1rem;
  font-size: 1.125rem;
  line-height: 1.75rem;
  font-weight: 500;
  letter-spacing: -0.025em;
  color: #ff0000;
}

.modalspan {
  padding-top: 1rem;
  font-weight: 500;
  letter-spacing: -0.025em;
  color: #000;
}

.modalform {
  padding-top: 2rem;
  min-height: 300px;
}

.forminside {
  border-style: none;
  outline-style: none;
  color: #000000;
}

.modalX {
  position: absolute;
  top: 1rem;
  right: 1rem;
  color: #ffffff;
  cursor: pointer;
}

.modalSvg {
  width: 1.5rem;
  height: 1.5rem;
}

/* nhap nhay button */


