.voltage-button {
  position: relative;
}

.voltage-button button {
  color: white;
  background: transparent;
  padding: 10px 15px;
  border-radius: 5rem;
  border: 3px solid #fff;
  font-size: 1rem;
  line-height: 1em;
  letter-spacing: 0.075em;
  transition: background 0.3s;
  text-transform: uppercase;
}

@media screen and (max-width: 960px) {
  .voltage-button button {
    border: 3px solid #fff;
    padding: 15px 25px;
    text-transform: uppercase;
  }
}

.voltage-button button:hover {
  cursor: pointer;
  background: #fff;
  color: #ff0000;
  border: 3px solid #ff0000;
}

.voltage-button button + svg,
.voltage-button button + svg + .dots {
  opacity: 0.5;
}

@media screen and (max-width: 960px) {
  .voltage-button button + svg,
  .voltage-button button + svg + .dots {
    opacity: 0.8;
  }
}

.voltage-button button:hover + svg,
.voltage-button button:hover + svg + .dots {
  opacity: 0.8;
}

.voltage-button svg {
  display: block;
  position: absolute;
  top: -0.75em;
  left: -0.25em;
  width: calc(100% + 0.5em);
  height: calc(100% + 1.5em);
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.4s;
  transition-delay: 0.1s;
}

.voltage-button svg path {
  stroke-dasharray: 100;
  filter: url("#glow");
}

.voltage-button svg path.line-1 {
  stroke: #ebd197;
  stroke-dashoffset: 0;
  animation: spark-1 3s linear infinite;
}



.voltage-button svg path.line-2 {
  stroke: #ff0000;
  stroke-dashoffset: 500;
  animation: spark-2 3s linear infinite;
}

@media screen and (max-width: 960px) {
  .voltage-button svg path.line-1 {
    stroke: #bb9b49;
  }
  .voltage-button svg path.line-2 {
    stroke: #ebd197;
  }


}

.voltage-button .dots {
  opacity: 0;
  transition: opacity 0.3s;
  transition-delay: 0.4s;
}

.voltage-button .dots .dot {
  width: 1rem;
  height: 1rem;
  background: #fff;
  border-radius: 100%;
  position: absolute;
  opacity: 0;
}

.voltage-button .dots .dot-1 {
  top: 0;
  left: 20%;
  animation: fly-up 3s linear infinite;
}

.voltage-button .dots .dot-2 {
  top: 0;
  left: 55%;
  animation: fly-up 3s linear infinite;
  animation-delay: 0.5s;
}

.voltage-button .dots .dot-3 {
  top: 0;
  left: 80%;
  animation: fly-up 3s linear infinite;
  animation-delay: 1s;
}

.voltage-button .dots .dot-4 {
  bottom: 0;
  left: 30%;
  animation: fly-down 3s linear infinite;
  animation-delay: 2.5s;
}

.voltage-button .dots .dot-5 {
  bottom: 0;
  left: 65%;
  animation: fly-down 3s linear infinite;
  animation-delay: 1.5s;
}

@keyframes spark-1 {
  to {
    stroke-dashoffset: -1000;
  }
}

@keyframes spark-2 {
  to {
    stroke-dashoffset: -500;
  }
}

@keyframes fly-up {
  0% {
    opacity: 0;
    transform: translateY(0) scale(0.2);
  }

  5% {
    opacity: 1;
    transform: translateY(-1.5rem) scale(0.4);
  }

  10%,
  100% {
    opacity: 0;
    transform: translateY(-3rem) scale(0.2);
  }
}

@keyframes fly-down {
  0% {
    opacity: 0;
    transform: translateY(0) scale(0.2);
  }

  5% {
    opacity: 1;
    transform: translateY(1.5rem) scale(0.4);
  }

  10%,
  100% {
    opacity: 0;
    transform: translateY(3rem) scale(0.2);
  }
}
