.slide-in {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: #8f1f1e;
    transform-origin: left;
    z-index: 2000;
  }
  
  
  .slide-out {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      background: #0f0f0f;
      transform-origin:  right;
      z-index: 2000;
    }


/* ////Start transition/// */

.stairs .transition-container {
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  left: 0;
  top: 0;
  pointer-events: none;
  z-index: 2;
}
.stairs .transition-container div {
  position: relative;
  height: 100%;
  width: 100%;
  background-color: #8f1f1e;
}
.stairs .transition-background {
  position: fixed;
  width: 100%;
  height: 100vh;
  background-color: yellow;
  z-index: 1;
  pointer-events: none;
  top: 0;
  left: 0;
}



